
:root {
  --colorOffWhite: #efefef;
  --colorLightGray: #dedede;
  --colorLightGrayShade: #c5c5c5;
  --colorGray: #cccccc;
  --colorGrayShade: #b3b3b3;
  --colorDarkGray: #545454;
  --colorBlack: #000000;
  --colorBlue: #71b0f1;
  --colorBlueShade: #5897d8;
  --colorRed: #db6767;
  --colorRedShade: #c24e4e;
  --baseBorderRadius: 2px;
  --gridGap: 2px;
  --keyCapHeight: 35px;
}


.Typing {
    max-width: 100vi;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.notes {
    margin-top: 12px;
    color:white;
}
.keyboard {
  background-color: var(--colorLightGray);
  border-radius: var(--baseBorderRadius);
  box-shadow: 
    0 2px 2px 0 rgba(0,0,0,0.14),
    0 3px 1px -2px rgba(0,0,0,0.2), 
    0 1px 5px 0 rgba(0,0,0,0.12);

    display: grid;
      grid-gap: var(--gridGap);
    grid-template-columns: repeat(60, 10px);
    grid-template-rows: 60px;
    padding: 16px 7px 7px 7px;

}


.keyboard__key {
     display: flex;
  grid-column: span 4;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--keyCapHeight);
  background-color: var(--colorLightGray);
  background-image: 
    linear-gradient(to bottom right, var(--colorLightGray), var(--colorLightGrayShade));
  border-radius: var(--baseBorderRadius);
  
}
.keyboard__key:before {
    content: attr(data-key-text);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-family: "Arial";
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.442);
    opacity: 0.65;
    white-space: nowrap;
  }

  .keyboard__key::after {
    display: block;
    width: auto;
    height: calc(var(--keyCapHeight) - 8px);
    background-color: inherit;
    filter: contrast(105%);
    border-radius: calc(var(--baseBorderRadius) + 2px);
  }

  .keyboard__key--1-5u {
  grid-column: span 6;
}
.keyboard__key--modifier{
  color:blue;
}
/* 1.75u key */
.keyboard__key--1-75u {
  grid-column: span 7;
}

/* 2u key */
.keyboard__key--2u {
  grid-column: span 8;
}

/* 2.25u key */
.keyboard__key--2-25u {
  grid-column: span 9;
}

/* 2.5u key */
.keyboard__key--2-5u {
  grid-column: span 10;
}

/* 2.75u key */
.keyboard__key--2-75u {
  grid-column: span 11;
}

/* 6.25u space key */
.keyboard__key--6-25u {
  grid-column: span 25;
}
.wordAppear {
    
    margin-bottom: 25px;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
 
}
.promptshowinKeyboard {
    color:red;
    border-radius: 50%;
    border:2px solid blue;
    animation: showKey 1ms ease-in 1ms;
}
@keyframes showKey {
  from {
    transform: translateX(0%) scale(0);
  }

  to {
    transform: translateX(2%) scale(1
    );
  }
    
}
.prompt {
    position: absolute;
    font-size: 8rem;
    z-index: 99;
    padding:12px;
    color:rgba(0, 0, 0, 0.475);
    
}
.typeInput {
    width: 140px;
    height: 40px;
    padding:2px;
    font-size: 2rem;
    padding-left: 30px;
    border-radius: 50px;
    border:none;
    outline: none;
}

.typeInput::placeholder {
    font-size: 2rem;
}
.status, .lives, .score {
    color:white;
    padding:5px;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-evenly;
  
}
.lives, .score {
    width: 100%;
}
.status {
    margin-top:10px;
    width: 800px;
}
.restartBtn {
    padding:14px;
    outline: none;
    border-radius: 20%;
    border: none;
}

@media screen and (max-width: 573px) {  
   .wordAppear {
    margin-bottom: 15px;
    width: 100%;
    height: 200px;
    display: flex;

 
}
.typeInput {
    width: 40px;
    height: 40px;
    font-size: 1rem;
    padding-left: 10px;
    border:none;
    outline: none;
}
.prompt {

    font-size: 5rem;

    
}

.scoreIcon {
  color:gold;
}
.typeInput::placeholder {
    font-size: 1rem;
}
.status, .lives, .score {
    color:red;
    display: flex;
    
    align-items: center;
    align-items: center;
    justify-content: space-evenly;
  
}
.lives, .score {
    width: 100%;
}
.status {
    margin-top:10px;
    width: 400px;
}

  .keyboard__key--1-5u {
  display: none;
}

/* 1.75u key */
.keyboard__key--1-75u {
  display: none;
}

/* 2u key */
.keyboard__key--2u {
  display:none;
}

/* 2.25u key */
.keyboard__key--2-25u {
    display: none;
}

.keyboard__key_Shift{
  display: flex;
  font-size: 0.6rem;
  grid-column: span 4;
}

/* 2.5u key */
.keyboard__key--2-5u {
  display: none;
}

/* 2.75u key */
.keyboard__key--2-75u {
  grid-column: span 4;
}
.no_mobile {
    display: none;
}
/* 6.25u space key */
.keyboard__key--6-25u {
  display: none;
}
.keyboard {

    display: grid;
      grid-gap: var(--gridGap);
    grid-template-columns: repeat(44, 6px);
    grid-template-rows: 60px;
    padding: 16px 7px 7px 7px;

}
.restartBtn {
    padding:10px;
    outline: none;
    border-radius: 20%;
    border: none;
}

}
