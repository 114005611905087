
#root .container section:nth-child(2) {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns:  0.3fr 2.5fr 1fr 0.2fr;
        grid-template-columns:  0.3fr 2.5fr 1fr 0.2fr;
    -ms-grid-rows: 0.1fr 0.3fr 4.1fr 0.2fr 0.3fr;
        grid-template-rows: 0.1fr 0.3fr 4.1fr 0.2fr 0.3fr;
        grid-template-areas: 
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . .";
    background-color: #1F4373;
    color: white;
    align-items: end;

  }
 

  #root .container section:nth-child(2) aside {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    grid-row: 2;
    font-size: 30px;
  }

  #root .container section:nth-child(2) .scrumSvg {
    -ms-grid-column: 3;
    grid-column: 3;
    /* -ms-grid-column-span: 4;
    grid-column: 3 / span 4; */
    -ms-grid-row: 3;
    grid-row: 3;
  }
  #root .container section:nth-child(2) .scrumSvg svg {
    height: 80%;
    width: 80%;
    fill: white;
  }
 #root .container section:nth-child(2) form {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 3;
    grid-row: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
        -ms-flex-pack: space-between;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 90%;
  }
  #root .container section:nth-child(2) footer {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 2;
    grid-row: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 20px;
  }

  #root .container section:nth-child(2) form div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
  }
  #root .container section:nth-child(2) form .Groups div  {
    display: flex;
    align-items: center;
    width: 100%;
  }

  
  #root .container section:nth-child(2) form div label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  

  
  #root .container section:nth-child(2) form input {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 60px;
    font-size: 15px;
    padding-left: 10px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  
  #root .container section:nth-child(2) form input::-webkit-input-placeholder {
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form input:-ms-input-placeholder {
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form input::-ms-input-placeholder {
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form input::placeholder {
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form textarea {
    height: 40%;
    font-size: 15px;
    padding:15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  #root .container section:nth-child(2) form textarea::-webkit-input-placeholder {
    padding-left: 2px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form textarea:-ms-input-placeholder {
    padding-left: 2px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form textarea::-ms-input-placeholder {
    padding-left: 2px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form textarea::placeholder {
    padding-left: 2px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form button {
    width: 70px;
    height: 70px;
    -ms-flex-item-align: end;
        align-self: flex-end;
    outline: none;
    border: none;
    text-align: start;
    font-size: medium;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    background-color: black;
    border-radius: 50%;
  }
  #root .container section:nth-child(2) form button p {
    transform: rotate(-22deg) translateX(-9px) translateY(15px);
  }
  #root .container section:nth-child(2) form button:hover  {
    color: white;
    background-color: red;
  }

  #root .container section:nth-child(2) form div svg {
    font-size: 50px;
    margin: 0;
  }




  @media screen and (max-width: 573px) {
    
  #root .container section:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: space-around;
        -ms-flex-pack: space-around;
            justify-content: space-around;

  }
  #root .container section:nth-child(2) .scrumSvg {
    -ms-flex-item-align: end;
        align-self: flex-end;
    justify-self: flex-start;
    width: 60%;
  }
 
  #root .container section:nth-child(2) form button {
    width: 30%;
    height: 60px;
    text-align: center;
    -ms-flex-item-align: start;
        align-self: flex-start;
  }


  #root .container section:nth-child(2) form div label {
    display: none;
  }
  
  #root .container section:nth-child(2) form input::-webkit-input-placeholder {
    font-size: 16px;
  }
  
  #root .container section:nth-child(2) form input:-ms-input-placeholder {
    font-size: 16px;
  }
  
  #root .container section:nth-child(2) form input::-ms-input-placeholder {
    font-size: 16px;
  }
  
  #root .container section:nth-child(2) form input::placeholder {
    font-size: 16px;
  }
  #root .container section:nth-child(2) aside {
    padding-left: 20px;
  }
  }