body {
    color:black;
}

.selectSection {
    
    padding-top:130px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.passanger {
    display: flex;
    flex-direction: column;

}

.passanger > label {
    padding:12px;
}
.passanger > input , .passanger > select {
    padding: 12px;
}

.passbtnGrp > button {
    background-color: black;
    color:white;
    width: 100px;
    height: 50px;
}
.passbtnGrp {
    padding:12px;
    display: flex;
    justify-content: space-evenly;
}