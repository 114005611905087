@import url(https://fonts.googleapis.com/css2?family=Taviraj&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Corben:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@600&display=swap);
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: unset;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

#root {
  background-color: #1F4373;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root ul {
  list-style: none;
}

#root h1 {
  font-family: 'Taviraj', serif;
}

#root .container {
  width: 100%;
  height: 100%;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
}

#root .container section {
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 175px);
  height: 100vh;
  scroll-snap-align: start;
  scroll-margin-left: 175px;
  color: white;
}

#root .container section:nth-child(1) {
  width: 100%;
  font-size: 40px;
  background-color: #1F4373;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}
#root .container section:nth-child(1) .grouper {
  padding-left: 320px;
}
#root .container section:nth-child(1) .wrapper {
  display: flex;
  align-items: center;
          
}

#root .container section:nth-child(1) .wrapper .staticText {
  font-size: 60px;
  font-weight: 400;
}

#root .container section:nth-child(1) .wrapper .animatedText {
  margin-left: 20px;
  line-height: 90px;
  height: 90px;
  overflow: hidden;
}

#root .container section:nth-child(1) .wrapper .animatedText li {
  list-style: none;
  font-size: 60px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: slider 12s steps(4) infinite;
}

@keyframes slider {
  100% {
    top: -360px;
  }
}

#root .container section:nth-child(1) .wrapper .animatedText li::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1F4373;
  border-left: 2px solid white;
  animation: typing 3s steps(24) infinite;
  overflow: hidden;
}

@keyframes typing {
  100% {
    left: 100%;
  }
}

#root .container section:nth-child(2) .scrumSvg svg #board {
  animation: fills 3.5s ease-in infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #sprintLines {
  animation: fills 3.5s ease-in infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #hiddenIssueOne {
  animation: showHide 3.5s ease-in infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #hiddenIssueTwo {
  fill: #27538C;
  animation: showHide 3.5s ease-in infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #firstIssue {
  animation: showHide 8.5s ease-in infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #secondIssue {
  animation: showHide 4.5s ease-in infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #hiddenIssueFour {
  fill: white;
  animation: showHide 40.5s ease-in infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #hiddenIssueThree {
  animation: resolve 18s ease-in  infinite;
}

#root .container section:nth-child(2) .scrumSvg svg #women {
  animation: walk 20s ease-in-out infinite;
  animation-direction: 2;
}

@keyframes showHide {
  50% {
    opacity: 0.5;
    fill: none;
  }
  80% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes walk {
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(100px);
  }
}

@keyframes resolve {
  0% {
    opacity: 0;
    fill: none;
  }
  10% {
    opacity: 0.5;
  }
  25% {
    fill: white;
    transform: translateX(105px);
  }
  50% {
    opacity: 1;
    transform: translateX(305px);
    fill: white;
  }
  85% {
    transform: translateX(405px);
  }
  90% {
    fill: none;
  }
  100% {
    transform: translateX(505px);
  }
}

@keyframes fills {
  5% {
    fill: white;
  }
  10% {
    fill: blue;
  }
  50% {
    fill: red;
  }
  10% {
    fill: white;
  }
}


#root .container section:nth-child(3) {
  display: flex;
  background-color: #1F4373;
  color: white;
  height: 100%;
}

#root .container section:nth-child(3) .mapBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root .container section:nth-child(3) .mapBox .mapHeader {
  text-align: center;
  transform: translateX(-150px);
  background: rgba(255, 255, 255, 0.151);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.116);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0);
}

#root .container section:nth-child(3) .mapBox .mapboxgl-popup-content {
  background: rgba(255, 255, 255, 0.74);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: black;
}

#root .container section:nth-child(3) .mapBox .pinsContainer {
  background: rgba(255, 255, 255, 0.685);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#root .container section:nth-child(3) .mapBox .pinsContainer details ul li {
  color: black;
  list-style: none;
}

#root .container section:nth-child(3) .mapBox .pinsContainer .websiteLogo {
  width: 220px;
}

#root .container section:nth-child(3) .mapBox .pinsContainer .websiteLogo .websiteImageContainer {
  color: black;
}

#root .container section:nth-child(3) .mapBox .pinsContainer .websiteLogo img {
  width: 100%;
  object-fit: contain;
}

#root .container section:nth-child(3) .mapBox .pinsContainer .websiteLogo img[alt] {
  font-size: 25px;
}

#root .container section:nth-child(4) {
  color: #9296A0;
  background-color: #1F4373;
}

#root .container section:nth-child(4) .frontEnd {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#root .container section:nth-child(4) .frontEnd .softwareSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#root .container section:nth-child(4) .frontEnd .softwareSelection svg {
  width: 20%;
  height: 50%;
}

#root .container section:nth-child(4) .frontEnd .softwareSelection .software {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#root .container section:nth-child(4) .frontEnd .softwareSelection .software svg {
  width: 10%;
  height: 10%;
}

#root .container section:nth-child(4) .frontEnd .softwareSelection .operatingSystem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
}

#root .container section:nth-child(4) .frontEnd .softwareSelection .operatingSystem svg {
  width: 20%;
  height: 50%;
  fill: white;
}

#root .container section:nth-child(4) .frontEnd .skillGroups {
  display: flex;
  flex-direction: column;
  background-color: #1F4373;
  margin-bottom: 30px;
}

#root .container section:nth-child(4) .frontEnd .skillGroups .skillbtn {
  background-color: #1F4373;
  outline: none;
  border: none;
  font-size: larger;
  color: white;
  padding: 5px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 5%;
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
}

#root .container section:nth-child(4) .frontEnd .skill1 {
  margin-top: 10%;
  height: 100%;
  width: 100%;
}

#root .container section:nth-child(5) {
  display: flex;
  flex-direction: column;
}

#root .container section:nth-child(5) .footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#root .container section:nth-child(5) .footer svg {
  width: 30%;
}

#root .container section:nth-child(5) .swot {
  display: flex;
  align-items: center;
  grid-gap: 50px;
  gap: 50px;
  justify-content: space-evenly;
  flex: 1 1;
}

nav {
  z-index: 88;
  position: fixed;
  width: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  color: white;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

nav .MenuIcon {
  display: none;
}

nav .navItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

nav .navItems a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

nav .navItems a:hover {
  color: black;
}

nav .navItems a svg {
  width: 20%;
}

nav .navItems a svg:hover {
  fill: white;
}

nav .navigation {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

nav .SamimAzimiPhoto {
  width: 100%;


  margin: 0;
  background-color: #1F4373;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 50%;
  border: 0px solid hsla(0, 0%, 100%, 0.678);
  overflow: hidden;
  transition: all ease-in-out 6s;
}
nav > .SamimAzimiPhoto {
  transition: all ease 10s;
}
nav .SamimAzimiPhoto svg, nav .SamimAzimiPhoto img {
  
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

nav .SamimAzimiPhoto:hover {
  background: rgba(255, 255, 255, 0.25);
}


.custom-tooltip svg {
  fill: #FFFFFF;
  color: white;
  width: 50px;
}

.custom-tooltip .BackendLabel {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.custom-tooltip .BackendLabel h1 {
  color: red;
}

@media screen and (max-width: 573px) {
  nav {
    flex-direction: row;
    width: 30%;
    height: 120px;
    background-color: unset;
    border: unset;
    box-shadow: unset;
    -webkit-backdrop-filter: unset;
            backdrop-filter: unset;
  }
  nav .MenuIcon {
    display: unset;
    position: fixed;
    font-size: 50px;
    bottom: 5%;
    right: 5%;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
            z-index: 222;
  }

  nav .SamimAzimiPhoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  nav .SamimAzimiPhoto svg {
    padding: 0;
  }
  nav .SamimAzimiPhoto img {
    object-fit: cover;
    border-radius: 50%;
    width: 90%;
    height: 100%;
    object-position: center;
  }
  nav .navigation {
    display: none;
  }
  nav .active {
    display: flex;
    position: fixed;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 25%;
    bottom: 15%;
    right: 5%;
  }
  nav .active .navItems {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    font-size: 18px;
  }
  nav .active .navItems a {
    color: black;
    background: rgba(255, 255, 255, 0.842);
    box-shadow: 0 8px 12px 0 rgba(248, 248, 248, 0.801);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 253, 253, 0.63);
  }
  nav .active .navItems svg {
    display: none;
  }
  #root .container section {
    scroll-margin-left: 0;
    scroll-margin-top: 100px;
    width: 100vw;
    overflow: hidden;
  }
  #root .container section:nth-child(5) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #root .container section:nth-child(5) .footer {
    flex: 1 1;
    flex-direction: column;
  }
  #root .container section:nth-child(5) .footer svg {
    width: 50%;
  }
  #root .container section:nth-child(5) .swot {
    flex-direction: column;
    grid-gap: 1px;
    gap: 1px;
  }
  #root .container section:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 0;
  }
  #root .container section:nth-child(1) .headingtwo {
    order: 2;
  }
  #root .container section:nth-child(1) .wrapper {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #root .container section:nth-child(1) .wrapper .staticText {
    display: none;
  }
  #root .container section:nth-child(1) .wrapper .animatedText li {
    font-size: 30px;
    font-style: bold;
    font-weight: 200;
  }
  
  #root .container section:nth-child(2) form textarea {
    width: 100%;
    height: 150px;
  }
  #root .container section:nth-child(3) {
    display: flex;
    justify-content: flex-start;
  }
  #root .container section:nth-child(3) .mapBox {
    width: 100%;
    height: 100%;
  }
  #root .container section:nth-child(3) .mapBox .mapHeader {
    transform: unset;
    position: absolute;
    bottom: 63px;
    width: 100%;
    background: hsla(219, 100%, 50%, 0.689);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.116);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0);
  }
  #root .container section:nth-child(4) .frontEnd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #root .container section:nth-child(4) .frontEnd .skill1 {
    margin-top: 110px;
    height: 53%;
  }
  #root .container section:nth-child(4) .frontEnd .software {
    display: flex;
    flex-direction: row;
  }
  #root .container section:nth-child(4) .frontEnd .operatingSystem {
    display: flex;
    width: 50%;
    height: 50%;
  }
  #root .container section:nth-child(4) .frontEnd .operatingSystem svg {
    fill: white;
  }
  #root .container section .skillGroups {
    width: 80%;
    align-self: flex-start;
  }
}
/*# sourceMappingURL=main.css.map */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.icons {
    color: red;
    margin-right: 20px;
}

.logoiconstyle {
    color: gray;
}

.logoname {
    font-family: Corben, cursive;
}

.logosecondname {
    font-family: Cabin, cursive;
    color: red;
}

.grouplogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 100%;

    background: rgba(255, 255, 255, 0.459);
    box-shadow: 0 2px 2px 0 rgb(255, 255, 255);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgb(253, 253, 253);
}




#root .container section:nth-child(2) {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns:  0.3fr 2.5fr 1fr 0.2fr;
        grid-template-columns:  0.3fr 2.5fr 1fr 0.2fr;
    -ms-grid-rows: 0.1fr 0.3fr 4.1fr 0.2fr 0.3fr;
        grid-template-rows: 0.1fr 0.3fr 4.1fr 0.2fr 0.3fr;
        grid-template-areas: 
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . .";
    background-color: #1F4373;
    color: white;
    align-items: end;

  }
 

  #root .container section:nth-child(2) aside {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    grid-row: 2;
    font-size: 30px;
  }

  #root .container section:nth-child(2) .scrumSvg {
    -ms-grid-column: 3;
    grid-column: 3;
    /* -ms-grid-column-span: 4;
    grid-column: 3 / span 4; */
    -ms-grid-row: 3;
    grid-row: 3;
  }
  #root .container section:nth-child(2) .scrumSvg svg {
    height: 80%;
    width: 80%;
    fill: white;
  }
 #root .container section:nth-child(2) form {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 3;
    grid-row: 3;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 90%;
  }
  #root .container section:nth-child(2) footer {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 2;
    grid-row: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 20px;
  }

  #root .container section:nth-child(2) form div {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
  }
  #root .container section:nth-child(2) form .Groups div  {
    display: flex;
    align-items: center;
    width: 100%;
  }

  
  #root .container section:nth-child(2) form div label {
    display: flex;
    justify-content: space-around;
  }
  

  
  #root .container section:nth-child(2) form input {
    flex: 1 1;
    height: 60px;
    font-size: 15px;
    padding-left: 10px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  
  #root .container section:nth-child(2) form input:-ms-input-placeholder {
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form input::placeholder {
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form textarea {
    height: 40%;
    font-size: 15px;
    padding:15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  #root .container section:nth-child(2) form textarea:-ms-input-placeholder {
    padding-left: 2px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form textarea::placeholder {
    padding-left: 2px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-family: 'Taviraj', serif;
  }
  
  #root .container section:nth-child(2) form button {
    width: 70px;
    height: 70px;
    align-self: flex-end;
    outline: none;
    border: none;
    text-align: start;
    font-size: medium;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    background-color: black;
    border-radius: 50%;
  }
  #root .container section:nth-child(2) form button p {
    transform: rotate(-22deg) translateX(-9px) translateY(15px);
  }
  #root .container section:nth-child(2) form button:hover  {
    color: white;
    background-color: red;
  }

  #root .container section:nth-child(2) form div svg {
    font-size: 50px;
    margin: 0;
  }




  @media screen and (max-width: 573px) {
    
  #root .container section:nth-child(2) {
    display: flex;
    align-items: stretch;
    justify-content: space-around;

  }
  #root .container section:nth-child(2) .scrumSvg {
    align-self: flex-end;
    justify-self: flex-start;
    width: 60%;
  }
 
  #root .container section:nth-child(2) form button {
    width: 30%;
    height: 60px;
    text-align: center;
    align-self: flex-start;
  }


  #root .container section:nth-child(2) form div label {
    display: none;
  }
  
  #root .container section:nth-child(2) form input:-ms-input-placeholder {
    font-size: 16px;
  }
  
  #root .container section:nth-child(2) form input::placeholder {
    font-size: 16px;
  }
  #root .container section:nth-child(2) aside {
    padding-left: 20px;
  }
  }

:root {
  --colorOffWhite: #efefef;
  --colorLightGray: #dedede;
  --colorLightGrayShade: #c5c5c5;
  --colorGray: #cccccc;
  --colorGrayShade: #b3b3b3;
  --colorDarkGray: #545454;
  --colorBlack: #000000;
  --colorBlue: #71b0f1;
  --colorBlueShade: #5897d8;
  --colorRed: #db6767;
  --colorRedShade: #c24e4e;
  --baseBorderRadius: 2px;
  --gridGap: 2px;
  --keyCapHeight: 35px;
}


.Typing {
    max-width: 100vi;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.notes {
    margin-top: 12px;
    color:white;
}
.keyboard {
  background-color: #dedede;
  background-color: var(--colorLightGray);
  border-radius: 2px;
  border-radius: var(--baseBorderRadius);
  box-shadow: 
    0 2px 2px 0 rgba(0,0,0,0.14),
    0 3px 1px -2px rgba(0,0,0,0.2), 
    0 1px 5px 0 rgba(0,0,0,0.12);

    display: grid;
      grid-gap: 2px;
      grid-gap: var(--gridGap);
    grid-template-columns: repeat(60, 10px);
    grid-template-rows: 60px;
    padding: 16px 7px 7px 7px;

}


.keyboard__key {
     display: flex;
  grid-column: span 4;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 35px;
  height: var(--keyCapHeight);
  background-color: #dedede;
  background-color: var(--colorLightGray);
  background-image: 
    linear-gradient(to bottom right, #dedede, #c5c5c5);
  background-image: 
    linear-gradient(to bottom right, var(--colorLightGray), var(--colorLightGrayShade));
  border-radius: 2px;
  border-radius: var(--baseBorderRadius);
  
}
.keyboard__key:before {
    content: attr(data-key-text);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-family: "Arial";
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.442);
    opacity: 0.65;
    white-space: nowrap;
  }

  .keyboard__key::after {
    display: block;
    width: auto;
    height: calc(35px - 8px);
    height: calc(var(--keyCapHeight) - 8px);
    background-color: inherit;
    filter: contrast(105%);
    border-radius: calc(2px + 2px);
    border-radius: calc(var(--baseBorderRadius) + 2px);
  }

  .keyboard__key--1-5u {
  grid-column: span 6;
}
.keyboard__key--modifier{
  color:blue;
}
/* 1.75u key */
.keyboard__key--1-75u {
  grid-column: span 7;
}

/* 2u key */
.keyboard__key--2u {
  grid-column: span 8;
}

/* 2.25u key */
.keyboard__key--2-25u {
  grid-column: span 9;
}

/* 2.5u key */
.keyboard__key--2-5u {
  grid-column: span 10;
}

/* 2.75u key */
.keyboard__key--2-75u {
  grid-column: span 11;
}

/* 6.25u space key */
.keyboard__key--6-25u {
  grid-column: span 25;
}
.wordAppear {
    
    margin-bottom: 25px;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
 
}
.promptshowinKeyboard {
    color:red;
    border-radius: 50%;
    border:2px solid blue;
    animation: showKey 1ms ease-in 1ms;
}
@keyframes showKey {
  from {
    transform: translateX(0%) scale(0);
  }

  to {
    transform: translateX(2%) scale(1
    );
  }
    
}
.prompt {
    position: absolute;
    font-size: 8rem;
    z-index: 99;
    padding:12px;
    color:rgba(0, 0, 0, 0.475);
    
}
.typeInput {
    width: 140px;
    height: 40px;
    padding:2px;
    font-size: 2rem;
    padding-left: 30px;
    border-radius: 50px;
    border:none;
    outline: none;
}

.typeInput:-ms-input-placeholder {
    font-size: 2rem;
}

.typeInput::placeholder {
    font-size: 2rem;
}
.status, .lives, .score {
    color:white;
    padding:5px;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-evenly;
  
}
.lives, .score {
    width: 100%;
}
.status {
    margin-top:10px;
    width: 800px;
}
.restartBtn {
    padding:14px;
    outline: none;
    border-radius: 20%;
    border: none;
}

@media screen and (max-width: 573px) {  
   .wordAppear {
    margin-bottom: 15px;
    width: 100%;
    height: 200px;
    display: flex;

 
}
.typeInput {
    width: 40px;
    height: 40px;
    font-size: 1rem;
    padding-left: 10px;
    border:none;
    outline: none;
}
.prompt {

    font-size: 5rem;

    
}

.scoreIcon {
  color:gold;
}
.typeInput:-ms-input-placeholder {
    font-size: 1rem;
}
.typeInput::placeholder {
    font-size: 1rem;
}
.status, .lives, .score {
    color:red;
    display: flex;
    
    align-items: center;
    align-items: center;
    justify-content: space-evenly;
  
}
.lives, .score {
    width: 100%;
}
.status {
    margin-top:10px;
    width: 400px;
}

  .keyboard__key--1-5u {
  display: none;
}

/* 1.75u key */
.keyboard__key--1-75u {
  display: none;
}

/* 2u key */
.keyboard__key--2u {
  display:none;
}

/* 2.25u key */
.keyboard__key--2-25u {
    display: none;
}

.keyboard__key_Shift{
  display: flex;
  font-size: 0.6rem;
  grid-column: span 4;
}

/* 2.5u key */
.keyboard__key--2-5u {
  display: none;
}

/* 2.75u key */
.keyboard__key--2-75u {
  grid-column: span 4;
}
.no_mobile {
    display: none;
}
/* 6.25u space key */
.keyboard__key--6-25u {
  display: none;
}
.keyboard {

    display: grid;
      grid-gap: 2px;
      grid-gap: var(--gridGap);
    grid-template-columns: repeat(44, 6px);
    grid-template-rows: 60px;
    padding: 16px 7px 7px 7px;

}
.restartBtn {
    padding:10px;
    outline: none;
    border-radius: 20%;
    border: none;
}

}

body {
    color:black;
}

.selectSection {
    
    padding-top:130px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.passanger {
    display: flex;
    flex-direction: column;

}

.passanger > label {
    padding:12px;
}
.passanger > input , .passanger > select {
    padding: 12px;
}

.passbtnGrp > button {
    background-color: black;
    color:white;
    width: 100px;
    height: 50px;
}
.passbtnGrp {
    padding:12px;
    display: flex;
    justify-content: space-evenly;
}
.lastMileLoginForm {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border:1px solid white;
}
.lastMileLoginForm > h1 {
    text-align: center;
}
.lastMileLoginForm > label {
    padding:10px;
    
}
.lastMileLoginForm > input {
     padding:10px;
    width: 50%;
    outline: none;
    border:none;
}
.lastMileLoginForm > .grpTel > input {
    padding:10px;
    outline: none;
    border:none;
}
.containers {
    display: flex;
    align-items: center;
    justify-content: center;
} 
.grpTel {
    display: flex;
}  
.countryCodeInput {
    width: 50px;
    text-align: center;
    margin-right:5px;
    margin-left: 5px;

}
.btngrp {
    margin: 20px;
    display: flex;
    align-self: center;
}
.btngrp > button {
    border:none;
    padding:20px;
    margin-right: 10px;
    align-items: center;
}
.btngrp > button:hover {
    background-color: red;
    color: white;
}
.countryCodeInput:disabled {
    background-color: white;
    outline: none;
    border:none;
}
.flag {
    width: 50px;

}
.verification {
    display: flex;
    flex-direction: column;
    width: 50%;
   
}
.verification > input {
    padding:20px;
    width: 50%;
    margin-top:12px;
    margin-bottom: 12px;
}

.verification >div> button {
    padding:10px;
    margin-right: 10%;
    outline: none;
    border: none;
}
.verification >div> button:hover {
    background-color: white;
    padding:10px;
    margin-right: 10%;
    outline: none;
    border: 2px solid yellow;
}

.menu {
    border:1px solid black;
    height: 90svh;
    width: 200px;
    display: flex;
}
.menu > ul {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
}
.menu > ul >li {
  text-align: center;
}
.menu > ul >li:hover{
   border:1px solid black;
   background-color: white;
  border-radius: 25px 25px;
}
.mainPageLastMile {
    display: flex;
    flex-direction: row;
}
