.lastMileLoginForm {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border:1px solid white;
}
.lastMileLoginForm > h1 {
    text-align: center;
}
.lastMileLoginForm > label {
    padding:10px;
    
}
.lastMileLoginForm > input {
     padding:10px;
    width: 50%;
    outline: none;
    border:none;
}
.lastMileLoginForm > .grpTel > input {
    padding:10px;
    outline: none;
    border:none;
}
.containers {
    display: flex;
    align-items: center;
    justify-content: center;
} 
.grpTel {
    display: flex;
}  
.countryCodeInput {
    width: 50px;
    text-align: center;
    margin-right:5px;
    margin-left: 5px;

}
.btngrp {
    margin: 20px;
    display: flex;
    align-self: center;
}
.btngrp > button {
    border:none;
    padding:20px;
    margin-right: 10px;
    align-items: center;
}
.btngrp > button:hover {
    background-color: red;
    color: white;
}
.countryCodeInput:disabled {
    background-color: white;
    outline: none;
    border:none;
}
.flag {
    width: 50px;

}
.verification {
    display: flex;
    flex-direction: column;
    width: 50%;
   
}
.verification > input {
    padding:20px;
    width: 50%;
    margin-top:12px;
    margin-bottom: 12px;
}

.verification >div> button {
    padding:10px;
    margin-right: 10%;
    outline: none;
    border: none;
}
.verification >div> button:hover {
    background-color: white;
    padding:10px;
    margin-right: 10%;
    outline: none;
    border: 2px solid yellow;
}

.menu {
    border:1px solid black;
    height: 90svh;
    width: 200px;
    display: flex;
}
.menu > ul {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
}
.menu > ul >li {
  text-align: center;
}
.menu > ul >li:hover{
   border:1px solid black;
   background-color: white;
  border-radius: 25px 25px;
}
.mainPageLastMile {
    display: flex;
    flex-direction: row;
}