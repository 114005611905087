@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Corben:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@600&display=swap");

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.icons {
    color: red;
    margin-right: 20px;
}

.logoiconstyle {
    color: gray;
}

.logoname {
    font-family: Corben, cursive;
}

.logosecondname {
    font-family: Cabin, cursive;
    color: red;
}

.grouplogo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    line-height: 100%;

    background: rgba(255, 255, 255, 0.459);
    -webkit-box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    box-shadow: 0 2px 2px 0 rgb(255, 255, 255);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgb(253, 253, 253);
}


